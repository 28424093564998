export const ROUTES = {
    LANDING: '/',
    HOME: '/home',
    USERS: '/user',
    EXPERIENCER: '/experiencer/:id',
    EXPERIENCERS: '/experiencer',
    EXPERIENCE: '/experience/:id',
    EXPERIENCES: '/experience',
    CONTRACT: '/contract/:id',
    CONTRACTS: '/contract',
    HOST: '/host/:id',
    HOSTS: '/host',
    BOOKING: '/booking/:id',
    BOOKINGS: '/booking',
    PAYMENT: '/payment/:id',
    PAYMENTS: '/payment',
    BUNDLE: '/bundle/:id',
    BUNDLES: '/bundle',
    RESOURCE: '/resource/:id',
    RESOURCES: '/resource',
    DISCOUNTS: '/discount',
    DISCOUNT: '/discount/:id',
    MARKUPS: '/markup',
    MARKUP: '/markup/:id',
    PRICES: '/price',
    PRICE: '/price/:id',
    REPORTS: '/report',
    // LANDINGHOSTEL: '/hostel',
    // BOOKING: '/booking/:id',
    // BOOKING_CODE: '/booking/:id/code/:code',
    // CHECKOUT: '/checkout',
    // CHECKOUT_VALIDATION: '/checkout/validation',
    // SAVEDEXPERIENCES: '/saved-experiences',
    // QR: '/qr',
    // PURCHASE: '/purchase',
    // ERROR: '/error',
    // PENDING: '/pending'
};
